@import 'buttons';

.secondary-button {
    @include button(2rem, false, true);

    background-color: transparent;
    border-color: var(--button-secondary-border-color);
    color: var(--button-secondary-text);
    font-size: 1rem;
    will-change: border-color, color, text-shadow;

    &[aria-disabled='true'],
    &:disabled {
        border-color: $gray-disabled !important;

        &,
        &:active,
        &:focus,
        &:hover {
            color: $gray-disabled-accent !important;
        }
    }

    &:not([aria-disabled='true']),
    &:not(:disabled) {

        /* stylelint-disable-line rule-empty-line-before*/
        &:active,
        &:focus,
        &:hover {
            border-color: var(--button-secondary-border-color-focus);
            color: var(--button-secondary-text-focus);
        }
    }
}