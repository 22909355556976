@import 'auth';

.form-footer {
    .secondary-resend {
        margin-bottom: 0.75rem !important;
        margin-left: 1rem;
    }
}

.center {
    text-align: center;
}

.validate-button {
    margin-bottom: 0.75rem !important;
}

.secret {
    button {
        border: none;
        box-shadow: none !important;
        color: inherit;
        padding: 0.5rem;
    }

    div {
        display: inline;
    }

    .icon {
        color: $primary;
        margin-left: 0.5rem;
    }

    .copy {
        color: $primary;
        margin-left: 0.5rem;
        position: absolute;
    }

    .show {
        display: inline-block;
    }

    .hide {
        display: none;
    }
}

.inline {
    display: inline;
}

.or {
    margin-bottom: 0.30rem;
    margin-top: -0.5rem;
}

